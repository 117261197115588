import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = ['active']
  declare activeValue: number

  static targets = ['count']
  declare countTargets: HTMLElement[]

  connect (): void {
    this.update()
    addEventListener('turbo:morph', () => this.update())
  }

  toggle (e): void {
    const scopeElement: HTMLElement = e.params.scope !== undefined ? e.target.closest(e.params.scope) : this.element
    const checkboxes: HTMLInputElement[] = this.checkboxesIn(scopeElement)

    if (this.allChecked(checkboxes)) {
      this.uncheck(checkboxes)
    } else {
      this.check(checkboxes)
    }
  }

  update (): void {
    this.countTargets.forEach((target: HTMLElement) => {
      target.textContent = this.checkedBoxes.length.toString()
    })
  }

  stop (e: Event): void {
    e.stopPropagation()
  }

  reset (): void {
    this.uncheck(this.allCheckboxes)
  }

  private get allCheckboxes (): HTMLInputElement[] {
    return this.checkboxesIn(this.element)
  }

  private get checkedBoxes (): HTMLInputElement[] {
    return this.allCheckboxes.filter((checkbox: HTMLInputElement): boolean => checkbox.checked)
  }

  private check (checkboxes: HTMLInputElement[], value: boolean = true): void {
    checkboxes.forEach(function (checkbox) {
      checkbox.checked = value
      checkbox.dispatchEvent(new Event('change', { bubbles: true }))
    })
  }

  private uncheck (checkboxes: HTMLInputElement[]): void {
    this.check(checkboxes, false)
  }

  private allChecked (checkboxes: HTMLInputElement[]): boolean {
    return checkboxes.length > 0 && checkboxes.every((checkbox: HTMLInputElement): boolean => checkbox.checked)
  }

  private checkboxesIn (scopeElement: HTMLElement): HTMLInputElement[] {
    return Array.from(scopeElement.querySelectorAll('.selection[type=checkbox]:not([disabled])'))
  }
}

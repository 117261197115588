<script setup lang="ts">
import ComboBoxV2 from './ComboBoxV2.vue'
import createGraphQLSource from './sources/createGraphQLSource'
import { uniqueId } from 'lodash-es'
import { useComboBoxStore, ComboBoxStore } from './store'
import { onBeforeMount, onMounted } from 'vue'
import { useEventListener } from '@vueuse/core'

const props = defineProps<{
  name: string
  skills: string[]
  hint: string
}>()

const combobox = ref(null)

const store: ComboBoxStore = useComboBoxStore(uniqueId('skillStore'))
const { source, selections } = storeToRefs(store)

onBeforeMount(async () => {
  source.value = await createGraphQLSource('SkillQuery')
})

onMounted(() => {
  if (combobox?.value !== null) {
    const form = (combobox.value as HTMLElement).closest('form')
    useEventListener(form, 'reset', () => {
      selections.value = []
    })
  }
})

selections.value = props.skills.map(skill => store.textToResult(skill))
</script>

<template>
  <div ref="combobox">
    <ComboBoxV2
      :name="name"
      :store="store"
      :readonly="false"
      :hint="hint"
      placeholder="Search Skills"
    />
  </div>
</template>

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  insertHeader (event: Event): void {
    const nextCandidacyId = this.findNextId()

    if (nextCandidacyId !== null) {
      console.log('Setting next ID', nextCandidacyId)
      event.detail.fetchOptions.headers['X-Next-Candidacy'] = nextCandidacyId
    }
  }

  findNextId (): number | null {
    let reachedSelectionBlock: boolean = false
    let nextId: number | null = null

    this.checkboxes.forEach(checkbox => {
      if (this.isSelectedOrActive(checkbox)) {
        reachedSelectionBlock = true
      }

      if (reachedSelectionBlock && nextId === null && !this.isSelectedOrActive(checkbox)) {
        nextId = parseInt(checkbox.value)
      }
    })

    return nextId
  }

  private isSelectedOrActive (checkbox: HTMLInputElement): boolean {
    const selected: boolean = checkbox.checked
    const active: boolean = checkbox.closest('.candidacy-card')?.classList.contains('active') ?? false
    return selected || active
  }

  private get checkboxes (): HTMLInputElement[] {
    return Array.from(document.querySelectorAll('.selection[type="checkbox"]:not([disabled])'))
  }
}

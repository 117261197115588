import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller<HTMLElement> {
  static targets = ['content']

  declare contentTarget: HTMLElement

  connect (): void {
    useClickOutside(this)
  }

  toggle (): void {
    this.contentTarget.classList.toggle('hidden')
  }

  open (): void {
    this.contentTarget.classList.toggle('hidden', false)
  }

  close (): void {
    this.contentTarget.classList.toggle('hidden', true)
  }

  clickOutside (): void {
    this.close()
  }
}

import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

import { computePosition, offset, flip, arrow, autoUpdate, Placement, OffsetOptions } from '@floating-ui/dom'

export default class extends Controller {
  static targets = ['content', 'arrow']

  private cleanup: () => void = () => {}
  private anchor: HTMLElement = document.createElement('a')

  static values = {
    placement: { type: String, default: 'bottom-end' },
    offset: { type: Number, default: 4 }
  }

  declare placementValue: Placement
  declare offsetValue: OffsetOptions

  declare contentTarget: HTMLElement
  declare arrowTarget: HTMLElement
  declare hasArrowTarget: boolean

  connect (): void {
    useClickOutside(this, { element: this.contentTarget })
  }

  clickOutside (e: MouseEvent): void {
    e.preventDefault()
    e.stopPropagation()
    this.hide()
  }

  show (e: any): void {
    if (e.currentTarget === null) return

    this.anchor = e.currentTarget as HTMLElement
    this.cleanup = this.setupFloatingUI()
    this.element.setAttribute('open', '')
    this.contentTarget.classList.remove('hidden')
  }

  hide (): void {
    this.cleanup()
    this.element.removeAttribute('open')
    this.contentTarget.classList.add('hidden')
  }

  toggle (e: MouseEvent): void {
    this.isVisible ? this.hide() : this.show(e)
  }

  get isVisible (): boolean {
    return this.element.hasAttribute('open')
  }

  private setupFloatingUI (): () => void {
    const middleware = [offset(this.offsetValue), flip()]

    if (this.hasArrowTarget) {
      middleware.push(arrow({ element: this.arrowTarget }))
    }

    return autoUpdate(this.anchor, this.contentTarget, () => {
      void computePosition(this.anchor, this.contentTarget, {
        placement: this.placementValue,
        middleware
      }).then(({ x, y, middlewareData }) => {
        Object.assign(this.contentTarget.style, {
          left: `${x}px`,
          top: `${y}px`
        })

        const arrowY = middlewareData.arrow?.y
        if (arrowY !== undefined) {
          Object.assign(this.arrowTarget.style, {
            top: `${arrowY}px`,
            left: '-4px'
          })
        }
      })
    })
  }
}

import { Controller } from '@hotwired/stimulus'
import Fuse from 'fuse.js'

interface FilterableNode {
  el: HTMLElement
  node: Node
  text: string
}

export default class extends Controller<HTMLFormElement> {
  private readonly fuse: Fuse<FilterableNode> = new Fuse([], {
    includeMatches: true,
    useExtendedSearch: true,
    keys: ['text']
  })

  static targets = ['item']
  declare itemTargets: HTMLElement[]

  itemTargetConnected (el: HTMLElement): void {
    const textWalker: TreeWalker = document.createTreeWalker(el, NodeFilter.SHOW_TEXT)

    while (textWalker.nextNode() !== null) {
      this.fuse.add({
        el,
        node: textWalker.currentNode,
        text: textWalker.currentNode.textContent ?? ''
      })
    }
  }

  apply (e: InputEvent): void {
    const query: string = (e.target as HTMLInputElement).value
    const results: Array<Fuse.FuseResult<FilterableNode>> = this.fuse.search(`'${query}`)

    this.highlight(results)
    this.toggleVisibility(query, results)
  }

  reset (e): void {
    e.currentTarget.value = ''
    e.currentTarget.dispatchEvent(new Event('input'))
  }

  private highlight (results: Array<Fuse.FuseResult<FilterableNode>>): void {
    CSS.highlights.clear()

    const highlightRanges: Range[] = results.map(result => {
      const range: Range = new Range()
      const match: Fuse.FuseResultMatch = (result.matches as Fuse.FuseResultMatch[])[0]

      range.setStart(result.item.node, match.indices[0][0])
      range.setEnd(result.item.node, match.indices[0][1] + 1)
      return range
    })

    const highlight = new Highlight(...highlightRanges.flat())
    CSS.highlights.set('filter', highlight)
  }

  private toggleVisibility (query: string, results: Array<Fuse.FuseResult<FilterableNode>>): void {
    this.itemTargets.forEach(el => {
      const filteredOut = (query.trim().length > 0) && results.every(result => result.item.el !== el)

      el.classList.toggle('hidden', filteredOut)
      el.querySelectorAll('input[type=checkbox]').forEach(checkbox => {
        filteredOut ? checkbox.setAttribute('disabled', '') : checkbox.removeAttribute('disabled')
      })
    })
  }
}

import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller<HTMLElement> {
  connect (): void {
    void enter(this.element)
    setTimeout(() => this.remove(), 5000)
  }

  remove (): void {
    void leave(this.element)
      .then(() => this.element.remove())
  }
}

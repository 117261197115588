import { App } from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

export default function (app: App, environment: string): void {
  Sentry.init({
    app,
    environment,
    dsn: 'https://17cedb0d6da54807953a4200804df82e@o341047.ingest.sentry.io/4504795282079744',
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', 'app.iqrecruit.com', 'app.iqrecruit.dev', /^\//]
      })
    ]
  })
}

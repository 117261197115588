<script setup lang="ts">
import ComboBoxV2 from './ComboBoxV2.vue'
import { staticSource } from './sources/staticSource'
import { uniqueId } from 'lodash-es'
import { useComboBoxStore, ComboBoxStore } from './store'
import { onBeforeMount } from 'vue'

const props = defineProps<{
  name: string
  industries: string[]
}>()

const store: ComboBoxStore = useComboBoxStore(uniqueId('industryStore'))
const { source, selections, field } = storeToRefs(store)

const industryNames = [
  'Accounting',
  'Advertising & Marketing',
  'Aerospace',
  'Agribusiness',
  'Apparel & Fashion',
  'Arts',
  'Auto',
  'Biotechnology',
  'Chemical',
  'Commerce',
  'Computer & Internet',
  'Construction',
  'Consulting',
  'Consumer Products',
  'Defense',
  'Education & Research',
  'Electronics',
  'Energy',
  'Environment',
  'Financial Services',
  'Food & Beverage',
  'Furniture & home furnishings',
  'Government',
  'Healthcare',
  'Hospitality',
  'HR & Recruiting',
  'Industrial Engineering',
  'Insurance',
  'Legal Services',
  'Logistics',
  'Manufacturing',
  'Maritime',
  'Media & Broadcasting',
  'Medical Devices & Supplies',
  'Mining',
  'Misc.',
  'News & Publishing',
  'Package delivery',
  'Pharmaceutical',
  'Philanthropy & Non-profit',
  'Public Administration',
  'Public Safety',
  'Real Estate',
  'Recreation',
  'Religious & Public institutions',
  'Retail',
  'Services',
  'Sports',
  'Telecommunications',
  'Transportation & Airlines',
  'Travel & Tourism',
  'Utilities',
  'Video Game'
]

onBeforeMount(async () => {
  source.value = staticSource(industryNames)
  field.value = 'industry'
})

selections.value = props.industries.map(industry => store.textToResult(industry))
</script>

<template>
  <ComboBoxV2
    :name="name"
    :store="store"
    :readonly="true"
    placeholder="Select industries"
    :is-static="true"
  />
</template>

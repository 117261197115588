import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  static targets = ['pill', 'error']

  declare pillTarget: HTMLElement
  declare errorTargets: HTMLElement[]
  declare hasPillTarget: boolean

  connect (): void {
    if (this.hasPillTarget) {
      this.pillTarget.innerText = this.errorTargets.length.toString()
      if (this.errorTargets.length > 0) {
        this.pillTarget.classList.remove('hidden')
      }
    }
  }
}

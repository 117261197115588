<script setup lang="ts">
import ComboBoxV2 from './ComboBoxV2.vue'
import createGraphQLSource from './sources/createGraphQLSource'
import { uniqueId } from 'lodash-es'
import { useComboBoxStore, ComboBoxStore } from './store'
import { onBeforeMount } from 'vue'

const props = defineProps<{
  name: string
  companies: string[]
}>()

const store: ComboBoxStore = useComboBoxStore(uniqueId('companyStore'))
const { source, selections } = storeToRefs(store)

onBeforeMount(async () => {
  source.value = await createGraphQLSource('CompanyQuery')
})

selections.value = props.companies.map(company => store.textToResult(company))
</script>

<template>
  <ComboBoxV2
    :name="name"
    :store="store"
    :readonly="false"
    placeholder="Search for companies"
  />
</template>

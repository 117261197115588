import { Controller } from '@hotwired/stimulus'
import { Loader } from '@googlemaps/js-api-loader'
import { debounce } from 'lodash-es'

const googleLoader = new Loader({
  apiKey: 'AIzaSyAtXz59ctNwkkgvc__MOnn4AC7662Zt2O8',
  version: 'beta',
  libraries: ['places']
})

const { AutocompleteSuggestion } = await googleLoader.importLibrary('places')

export default class extends Controller<HTMLElement> {
  static targets = ['placeId', 'placeName', 'template', 'results']

  declare placeIdTarget: HTMLInputElement
  declare placeNameTarget: HTMLInputElement
  declare templateTarget: HTMLTemplateElement
  declare resultsTarget: HTMLElement
  search: (event: InputEvent) => Promise<void> = async () => {}

  initialize (): void {
    this.search = debounce(this._search, 500)
  }

  select (e): void {
    this.placeIdTarget.value = e.target.dataset.placeId
    this.placeNameTarget.value = e.target.dataset.placeName
  }

  private async _search (): Promise<void> {
    if (this.query.length === 0) {
      this.clear()
      return
    }

    const { suggestions } = await AutocompleteSuggestion.fetchAutocompleteSuggestions({
      input: this.placeNameTarget.value,
      includedPrimaryTypes: [
        'locality',
        'administrative_area_level_1',
        'administrative_area_level_2'
      ]
    })

    this.clear()

    suggestions.forEach(suggestion => {
      const place = suggestion.placePrediction?.toPlace()
      if (place !== undefined) {
        void this.renderPlace(place)
      }
    })
  }

  private async renderPlace (place: google.maps.places.Place): Promise<void> {
    const resultTemplate = this.templateTarget.content.cloneNode(true) as DocumentFragment
    const resultElement = resultTemplate.children[0] as HTMLElement

    await place.fetchFields({
      fields: ['id', 'formattedAddress']
    })

    resultElement.dataset.placeId = place.id
    resultElement.dataset.placeName = place.formattedAddress
    resultElement.textContent = place.formattedAddress

    this.resultsTarget.appendChild(resultElement)
  }

  private clear (): void {
    this.resultsTarget.innerHTML = ''
  }

  private get query (): string {
    return this.placeNameTarget.value ?? ''
  }
}

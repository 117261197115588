import { DirectUpload } from '@rails/activestorage'
import Alpine from 'alpinejs'

class Attachment {
  file: File
  progress: number
  blobId: string
  errors: string[]

  constructor (file: File) {
    this.file = file
    this.progress = 0
    this.blobId = ''
    this.errors = []
  }

  get name (): string {
    return this.file.name
  }

  upload (): void {
    const url = '/rails/active_storage/direct_uploads'
    const directUpload = new DirectUpload(this.file, url, this)
    directUpload.create((error, blob) => {
      if (error !== null) {
        console.error('File upload error:', error)
      } else {
        this.blobId = blob.signed_id
      }
    })
  }

  directUploadWillStoreFileWithXHR (request: XMLHttpRequest): void {
    request.upload.addEventListener('progress', (e) => {
      this.progress = (e.loaded / e.total) * 100
    })
  }
}

export default (): any => ({
  attachments: [],

  upload (event: Event): void {
    const target = event.target as HTMLInputElement
    if (target.files != null) {
      Array.from(target.files).forEach(file => this.uploadEach(file))
    }
  },

  uploadEach (file: File) {
    const attachment = Alpine.reactive(new Attachment(file))

    this.validateFileType(attachment)
    this.validateFileSize(attachment)

    this.attachments.push(attachment)
    if (attachment.errors.length === 0) {
      attachment.upload()
    }
  },

  detach (attachment: Attachment): void {
    this.attachments = this.attachments.filter((a: Attachment) => a !== attachment)
  },

  validateFileType (attachment: Attachment): void {
    const allowedTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'application/pdf',
      'text/plain'
    ]
    if (!allowedTypes.includes(attachment.file.type)) {
      attachment.errors.push('File type must be .doc, .docx, .pdf, or .txt')
    }
  },

  validateFileSize (attachment: Attachment): void {
    const maxFileSize = 4 * 1024 * 1024
    if (attachment.file.size > maxFileSize) {
      attachment.errors.push('File size must be less than 4mb')
    }
  }
})

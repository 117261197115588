import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLFormElement> {
  static targets = ['checkbox', 'count']

  declare checkboxTarget: HTMLInputElement
  declare checkboxTargets: HTMLInputElement[]
  declare countTarget: HTMLElement
  declare countTargets: HTMLElement[]

  update (): void {
    this.countTargets.forEach(target => {
      target.dataset.count = this.count.toString()
    })
  }

  reset (): void {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
    })

    this.update()
  }

  get count (): number {
    return this.checkboxTargets.filter(checkbox => checkbox.checked).length
  }

  get candidacyIds (): number[] {
    return this.checkboxTargets.filter(checkbox => checkbox.checked).map(checkbox => parseInt(checkbox.value))
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLFormElement> {
  static targets = ['collapsible']

  declare collapsibleTargets: HTMLDetailsElement[]

  closeOthers (e: ToggleEvent): void {
    const collapsible = e.target as HTMLDetailsElement

    if (collapsible.open) {
      this.collapsibleTargets
        .filter(details => details !== collapsible)
        .forEach(details => this.close(details))
    }
  }

  close (details: HTMLDetailsElement): void {
    details.removeAttribute('open')
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']
  declare itemTargets: HTMLElement[]

  activate (e): void {
    this.itemTargets.forEach(item => item.classList.remove('active'))
    e.currentTarget.classList.add('active')
  }
}

<script setup lang="ts">
import type { ComboBoxStore } from './store'
import { toRefs } from 'vue'
import { OnClickOutside } from '@vueuse/components'

const props = defineProps<{
  store: ComboBoxStore
  name: string
  placeholder: string
  readonly: boolean
  hint?: string
  isStatic?: boolean
}>()

const { selections } = storeToRefs(props.store)
const { closeDropdown, isSingleSelect, query, useInputValueByDefault } = toRefs(props.store)
</script>

<template>
  <OnClickOutside @trigger="closeDropdown">
    <ComboBoxInputs
      :name="name"
      :selections="selections"
      :use-input-value-by-default="useInputValueByDefault"
      :user-input="query"
    />

    <DropdownV2
      :store="store"
      :placeholder="placeholder"
      :readonly="readonly"
      :is-static="isStatic"
    />

    <p class="mt-1 text-sm text-gray-400">
      {{ hint }}
    </p>

    <div
      v-if="!isSingleSelect"
      class="pt-2"
    >
      <ResultPill
        v-for="result in selections"
        :key="result.id"
        :store="store"
        :result="result"
      />
    </div>
  </OnClickOutside>
</template>

import { Controller } from '@hotwired/stimulus'

interface TurboFrameBeforeRenderEvent {
  detail: {
    render: (currElement: Element, newElement: Element) => void
  }
}

export default class extends Controller<HTMLFormElement> {
  static targets = ['collapsible']

  declare collapsibleTargets: HTMLDetailsElement[]

  start (event: TurboFrameBeforeRenderEvent): void {
    if (!this.supportsViewTransitions) {
      return
    }

    const originalRender = event.detail.render

    event.detail.render = (currElement, newElement) => {
      document.startViewTransition(() => {
        originalRender(currElement, newElement)
      })
    }
  }

  private get supportsViewTransitions (): boolean {
    return document.startViewTransition !== undefined
  }
}

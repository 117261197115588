import { Turbo } from '@hotwired/turbo-rails'
import '@/v2/controllers'
import 'trix'
import '@rails/actiontext'

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import anchor from '@alpinejs/anchor'
import persist from '@alpinejs/persist'
import uploader from '@/v2/alpine/uploader'

import type { TurboMorphEvent } from '@/v2/types'

Alpine.plugin(collapse)
Alpine.plugin(anchor)
Alpine.plugin(persist)
Alpine.data('uploader', uploader)

Alpine.start()

// Copy Alpine state to the new nodes during a morph
addEventListener('turbo:before-morph-element', ((e: TurboMorphEvent) => {
  const oldNode = e.target
  const newNode = e.detail.newElement

  if (oldNode.tagName.startsWith('TRIX-')) {
    e.preventDefault()
  }

  if (oldNode?._x_dataStack !== undefined && newNode !== undefined) {
    Alpine.clone(oldNode, newNode)
  }
}) as EventListener)

// Handle custom redirect event to bust out of turboframes
Turbo.StreamActions.redirect = function () {
  document.querySelector('dialog[open]')?.close()
  Turbo.visit(this.target)
}

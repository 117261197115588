<script setup lang="ts">
import type { ComboBoxStore } from './store'
import { toRefs } from 'vue'

const props = defineProps<{
  store: ComboBoxStore
  result: any
}>()

const { deselectResult } = toRefs(props.store)
</script>

<template>
  <span class="pt-2 cursor-pointer">
    <span
      :id="result.id"
      class="inline-block py-1 pr-1 pl-2 mt-1 mr-1 text-sm text-gray-700 rounded-md border duration-300 hover:bg-primary-50"
      @click="deselectResult(result)"
    >
      {{ result.text1 }}
      <i class="px-1 text-xs text-gray-400 fa-regular fa-times" />
    </span>
  </span>
</template>

import { App, Component, createApp } from 'vue'
import { Controller } from '@hotwired/stimulus'
import setupSentry from '@/v2/sentry'
import { createPinia } from 'pinia'
import { debounce } from 'lodash-es'
import { PiniaDebounce } from '@pinia/plugin-debounce'

interface ComponentDefinition {
  default: Component
}

// Eager-load and register root components
const components = import.meta.glob('@/v2/**/*.vue', { eager: true })
const componentDefinitions = Object.entries(components).map(([path, definition]) => {
  const componentName: string = path.split('/').pop()?.replace(/\.\w+$/, '') ?? ''
  return { componentName, definition: (definition as ComponentDefinition).default }
})

export default class extends Controller<HTMLElement> {
  private readonly app: App = createApp({})

  initialize (): void {
    componentDefinitions.forEach(({ componentName, definition }) => {
      this.app.component(componentName, definition)
    })
  }

  connect (): void {
    if (!this.isLocal()) {
      setupSentry(this.app, this.environment)
    }

    const pinia = createPinia()
    pinia.use(PiniaDebounce(debounce))

    this.app.use(pinia)
    this.app.mount(this.element)
  }

  disconnect (): void {
    this.app.unmount()
  }

  private isLocal (): boolean {
    return this.environment === 'development' || this.environment === 'test'
  }

  private get environment (): string {
    return RAILS_ENV
  }
}

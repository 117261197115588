export enum Factors {
  Location = 'location',
  Experience = 'experience',
  Seniority = 'seniority',
  Skills = 'skills',
  Diversity = 'diversity',
  PreviousEmployer = 'previous_employer',
  Education = 'education',
  Other = 'other'
}

export enum FactorsLabel {
  location = 'Location',
  experience = 'Experience',
  seniority = 'Seniority Level',
  skills = 'Certification or Specific Skill',
  diversity = 'Diversity Consideration',
  previous_employer = 'Previous Employer',
  education = 'Education',
  other = 'Other'
}

import { Controller } from '@hotwired/stimulus'
import { computePosition, offset, flip, autoUpdate } from '@floating-ui/dom'
import type { Placement, OffsetOptions } from '@floating-ui/dom'

export default class extends Controller {
  static readonly targets = ['anchor', 'element']

  private cleanup: () => void = () => {}

  static readonly values = {
    placement: { type: String, default: 'bottom-end' },
    offset: { type: Number, default: 4 },
    matchWidth: { type: Boolean, default: false },
    flip: { type: Boolean, default: true }
  }

  declare placementValue: Placement
  declare flipValue: boolean
  declare offsetValue: OffsetOptions
  declare matchWidthValue: boolean

  declare anchorTarget: HTMLElement
  declare elementTarget: HTMLElement

  connect (): void {
    this.updatePosition()
  }

  updatePosition (): void {
    const middleware = [offset(this.offsetValue)]

    if (this.flipValue) {
      middleware.push(flip())
    }

    computePosition(
      this.anchorTarget,
      this.elementTarget,
      { placement: this.placementValue, middleware }
    ).then(({ x, y }) => {
      Object.assign(this.elementTarget.style, {
        left: `${x}px`,
        top: `${y}px`
      })
    }).catch(() => {})
  }

  setPosition (e: ToggleEvent): void {
    (e.newState === 'open')
      ? this.onOpen()
      : this.onClose()
  }

  onOpen (): void {
    this.cleanup = autoUpdate(this.anchorTarget, this.elementTarget, () => {
      this.updatePosition()
    })
  }

  onClose (): void {
    this.cleanup()
  }

  anchorTargetConnected (anchor: HTMLElement): void {
    if (this.matchWidthValue) {
      const observer = new ResizeObserver(entries => {
        const anchorWidth = entries[0].contentRect.width
        this.elementTarget.style.width = `${anchorWidth}px`
      })

      observer.observe(anchor)
    }
  }
}

import { defineStore, Store, StoreDefinition } from 'pinia'
import { Source, NoopSource } from './sources'
import { Result } from './results'

export type ComboBoxStore = ReturnType<ReturnType<typeof defineComboBoxStore>>

// Use a unique store for each ComboBox: https://github.com/vuejs/pinia/discussions/840
const definedStores = new Map<string, StoreDefinition>()

// Cache ComboBox stores by storeId
export const useComboBoxStore = (storeId: string): Store => {
  if (!definedStores.has(storeId)) {
    definedStores.set(storeId, defineComboBoxStore(storeId))
  }

  const definition: StoreDefinition = definedStores.get(storeId) as StoreDefinition
  return definition()
}

// Actually define the store for a given storeId
function defineComboBoxStore<Id extends string> (storeId: Id): StoreDefinition {
  return defineStore(`combobox/${storeId}`, () => {
    const comboboxId = ref(storeId)
    const query = ref('')
    const field = ref('')
    const isLoading = ref(false)
    const isDropdownOpen = ref(false)
    const isSingleSelect = ref(false)
    const useInputValueByDefault = ref(false)
    const results = ref<Result[]>([])
    const selections = ref<Result[]>([])
    const source = ref<Source>(new NoopSource())

    const unselectedResults = computed(() => {
      return results.value.filter(result => !isResultSelected(result))
    })

    const hasQuery = computed<boolean>(() => {
      return query.value.replace(/\s+/, '').length > 0
    })

    function search (showResultsOnEmptySearch: boolean): void {
      if (query.value === '' && !showResultsOnEmptySearch) {
        closeDropdown()
        return
      }

      isDropdownOpen.value = true
      isLoading.value = true
      results.value = []

      source.value.fetch(query.value, field.value)
        .then(response => {
          if (response === undefined) return
          for (const value of Object.values(response.data)) {
            results.value = results.value.concat(value as any[])
          }
        }).finally(() => {
          isLoading.value = false
        })
    }

    function selectResult (result: Result): void {
      const isExistingValue = selections.value.some(val => val.value === result.value)

      if (!isExistingValue) {
        selections.value.push(result)
      }

      if (isSingleSelect.value) {
        query.value = result.text1
      } else {
        query.value = ''
      }

      closeDropdown()
    }

    function deselectResult (result: Result): void {
      selections.value = selections.value.filter((r: Result) => r.id !== result.id)
    }

    function isResultSelected (result: Result): boolean {
      return selections.value.some(r => r.id === result.id)
    }

    function closeDropdown (): void {
      isDropdownOpen.value = false
    }

    function toggleDropdown (): void {
      isDropdownOpen.value = !isDropdownOpen.value
    }

    function textToResult (text: string): Result {
      return {
        id: text,
        value: text,
        text1: text
      }
    }

    return {
      comboboxId,
      selections,
      unselectedResults,
      source,
      isLoading,
      isResultSelected,
      isDropdownOpen,
      isSingleSelect,
      useInputValueByDefault,
      hasQuery,
      query,
      field,
      closeDropdown,
      toggleDropdown,
      selectResult,
      deselectResult,
      search,
      textToResult
    }
  }, {
    debounce: {
      search: 300
    }
  })
}

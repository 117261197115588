import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static readonly targets = ['popover']

  declare popoverTarget: HTMLElement

  connect (): void {
    useClickOutside(this)
  }

  clickOutside (): void {
    this.hide()
  }

  show (): void {
    this.popoverTarget.showPopover()
  }

  hide (): void {
    this.popoverTarget.hidePopover()
  }

  toggle (): void {
    this.popoverTarget.togglePopover()
  }
}

<script setup lang="ts">
import { ref, toRefs } from 'vue'
import type { ComboBoxStore } from './store'
import type { Result } from './results'

const props = defineProps<{
  store: ComboBoxStore
  placeholder: string
  readonly: boolean
  isStatic?: boolean
}>()

const { query, isDropdownOpen, hasQuery, unselectedResults, isLoading } = storeToRefs(props.store)
const { search, selectResult, closeDropdown, toggleDropdown } = toRefs(props.store)

const newResult = computed<Result>(() => props.store.textToResult(query.value))
const showCreateNew = computed(() => hasQuery.value as boolean && !props.readonly)
const queryInput = ref()
</script>

<template>
  <div class="relative">
    <div class="relative">
      <input
        ref="queryInput"
        v-model="query"
        type="text"
        class="w-full placeholder-gray-300 text-black rounded-lg border border-gray-300 drop-shadow-sm"
        :placeholder="placeholder"
        @input="search"
        @focus="search(isStatic)"
        @keyup.esc="closeDropdown"
      >
      <i
        v-if="isStatic"
        :class="{'fa-chevron-up': isDropdownOpen, 'fa-chevron-down': !isDropdownOpen}"
        class="absolute right-3 top-1/2 text-gray-700 transform -translate-y-1/2 fa-regular"
        @click="isDropdownOpen ? toggleDropdown() : search(true)"
      />
      <i
        v-else
        class="absolute right-3 top-1/2 text-gray-700 transform -translate-y-1/2 fa-regular fa-magnifying-glass"
      />
    </div>
    <div
      v-if="isDropdownOpen"
      class="absolute z-50 mt-1 w-full drop-shadow-sm"
    >
      <div class="overflow-auto max-h-40 bg-white rounded-lg border">
        <div
          v-if="showCreateNew"
          class="py-1 pl-3 cursor-pointer hover:bg-primary-50"
          @click="selectResult(newResult)"
        >
          <span class="text-sm font-medium text-gray-700">
            <i class="mr-1 fa-regular fa-plus" /> Create {{ newResult.text1 }}
          </span>
        </div>

        <div
          v-if="isLoading"
          class="flex justify-center p-2"
        >
          <SpinnerV2 />
        </div>

        <div v-if="!isLoading && unselectedResults.length > 0">
          <ResultV2
            v-for="result in unselectedResults"
            :key="result.id"
            :store="store"
            :result="result"
          />
        </div>
        <div
          v-else-if="!isLoading && unselectedResults.length == 0"
          class="inline-block py-1 pl-3 text-sm font-medium text-gray-700"
        >
          No results found.
        </div>
      </div>
    </div>
  </div>
</template>

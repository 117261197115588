import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLFormElement> {
  static targets = ['input', 'counter']

  declare inputTarget: HTMLInputElement
  declare counterTarget: HTMLElement

  initialize (): void {
    this.update = this.update.bind(this)
  }

  connect (): void {
    this.update()
    this.inputTarget.addEventListener('input', this.update)
  }

  disconnect (): void {
    this.inputTarget.removeEventListener('input', this.update)
  }

  update (): void {
    this.counterTarget.textContent = `${this.characterCount}/${this.maxLength}`
  }

  get characterCount (): number {
    return this.inputTarget.value.length
  }

  get maxLength (): number {
    return this.inputTarget.maxLength
  }
}

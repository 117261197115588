import { Controller } from '@hotwired/stimulus'
import bronto from '@/images/v2/confetti/dino_bronto_64.png'
import terry from '@/images/v2/confetti/dino_terry_64.png'
import trex from '@/images/v2/confetti/dino_trex_64.png'
import trike from '@/images/v2/confetti/dino_trike_64.png'
import { confetti } from 'tsparticles-confetti'

export default class extends Controller<HTMLElement> {
  connect (): void {
    void this.showConfetti()
  }

  async showConfetti (): Promise<void> {
    await confetti({
      spread: 360,
      ticks: 200,
      gravity: 0.5,
      decay: 0.95,
      startVelocity: 30,
      particleCount: 300,
      shapes: ['image'],
      shapeOptions: {
        image: [
          {
            src: bronto,
            particles: {
              size: {
                value: 11
              }
            }
          },
          {
            src: terry,
            particles: {
              size: {
                value: 11
              }
            }
          },
          {
            src: trex,
            particles: {
              size: {
                value: 11
              }
            }
          },
          {
            src: trike,
            particles: {
              size: {
                value: 11
              }
            }
          }
        ]
      }
    })
  }
}

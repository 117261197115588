import { Controller } from '@hotwired/stimulus'

type Turboframe = Element & {
  reload: () => void
}

export default class extends Controller {
  connect (): void {
    const turboframe = this.element.closest('turbo-frame') as Turboframe
    turboframe?.reload()
    this.element.remove()
  }
}

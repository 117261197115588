import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLFormElement> {
  static values = { content: String }

  declare contentValue: string

  copy (): void {
    void navigator.clipboard.writeText(this.contentValue)
  }
}

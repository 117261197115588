import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  static targets = ['dialog', 'turboframe', 'visibility', 'wrapper']

  declare dialogTarget: HTMLDialogElement
  declare dialogTargets: HTMLDialogElement[]
  declare turboframeTarget: HTMLElement
  declare visibilityTarget: HTMLElement
  declare wrapperTarget: HTMLElement

  declare hasTurboframeTarget: boolean
  declare hasWrapperTarget: boolean

  private identifier: string | null = null

  open (e: PointerEvent): void {
    const { currentTarget }: { currentTarget: EventTarget | null } = e
    const href: string | null = (currentTarget as HTMLLinkElement)?.href ?? (currentTarget as HTMLElement)?.dataset?.href
    this.identifier = (e as any).params?.identifier

    if (href?.length >= 0) {
      e.preventDefault()
      e.stopPropagation()
      this.load(href)
    }

    this.show()
  }

  show (): void {
    this.dialog.showModal()
  }

  hide (): void {
    this.dialog.close()
  }

  hideIfSuccess (e: CustomEvent): void {
    if (e.detail.success === true) {
      this.hide()
    }
  }

  hideOnSuccess (e: Event): void {
    const button = e.currentTarget as HTMLButtonElement
    button.form?.addEventListener('turbo:submit-end', e => {
      this.hideIfSuccess(e as CustomEvent)
    }, { once: true })
  }

  hideIfBackdrop (e: PointerEvent): void {
    if (!this.hasWrapperTarget) return

    if (!this.wrapperTarget.contains(e.target as HTMLElement)) {
      this.hide()
    }
  }

  load (href: string): void {
    this.turboframeTarget.setAttribute('src', `${href}#t=${Date.now()}`)
  }

  async visibilityTargetConnected (target: HTMLElement): Promise<void> {
    const behavior = target.dataset.behavior

    if (behavior === 'show') {
      if (target.dataset.url !== undefined) {
        this.load(target.dataset.url)
      }

      this.show()
    } else if (behavior === 'hide') {
      this.hide()
    } else if (behavior === 'hacky-show') {
      addEventListener('turbo:before-render', e => {
        if (e.detail.renderMethod === 'morph') {
          this.show()
        } else {
          const autoshowElement = document.createElement('div')
          autoshowElement.dataset.modalTarget = 'visibility'
          autoshowElement.dataset.behavior = 'show'
          this.dialogTarget.appendChild(autoshowElement)
        }
      }, { once: true })
    }

    target.remove()
  }

  dialogTargetConnected (dialog: HTMLDialogElement): void {
    dialog.addEventListener('transitionend', () => {
      if (!dialog.open) this.resetTurboframe()
    })
  }

  private get dialog (): HTMLDialogElement {
    if (this.identifier === undefined || this.identifier === null) {
      return this.dialogTarget
    } else {
      return this.dialogTargets.find(dialog => dialog.dataset.identifier === this.identifier) as HTMLDialogElement
    }
  }

  private resetTurboframe (): void {
    if (this.hasTurboframeTarget) {
      this.turboframeTarget.innerHTML = ''
      this.turboframeTarget.removeAttribute('src')
    }
  }
}

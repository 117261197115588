import { Source } from '.'
import { ApolloClient, ApolloQueryResult, InMemoryCache } from '@apollo/client/core'

export default class implements Source {
  private readonly client: ApolloClient<any>
  private readonly gql: any

  constructor (gqlQuery: any) {
    this.gql = gqlQuery

    this.client = new ApolloClient({
      uri: '/guest/graphql',
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache'
        },
        query: {
          fetchPolicy: 'no-cache'
        }
      }
    })
  }

  async fetch (query: string, field: string): Promise<ApolloQueryResult<any>> {
    if (query.length === 0) {
      return await Promise.resolve({ data: { results: [] }, loading: false, networkStatus: 0 })
    }

    return await this.client.query({ query: this.gql, variables: { query, field } })
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLFormElement> {
  static targets = ['anchor', 'template']

  declare anchorTarget: HTMLElement
  declare templateTarget: HTMLTemplateElement

  add (): void {
    const newNode: HTMLElement = this.templateTarget.cloneNode(true) as HTMLElement
    const newHTML = newNode.innerHTML.replace(/NEW_RECORD/g, crypto.randomUUID())

    this.anchorTarget.insertAdjacentHTML('beforebegin', newHTML)
  }

  remove (e: Event): void {
    const checkbox = e.target as HTMLInputElement
    const wrapper = checkbox.closest('.nested-fields') as HTMLElement

    if (checkbox.checked && this.isNew(wrapper)) {
      wrapper.remove()
    }
  }

  private isNew (wrapper: HTMLElement): boolean {
    return wrapper.dataset.newRecord !== undefined
  }
}

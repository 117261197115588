<script setup lang="ts">
import { Factors, FactorsLabel } from './types'
import draggable from 'vuedraggable'

const props = defineProps<{
  factorsName: string
  factors: string[]
  factorsContextName: string
  factorsContext: string
  otherFactorName: string
  otherFactor: string
}>()

const topImportantFactors = ref<String[]>([])
const drag = ref(false)
const factorsText = ref()
const otherFactor = ref()

onMounted(() => {
  topImportantFactors.value = props.factors
  factorsText.value = props.factorsContext
  otherFactor.value = props.otherFactor
})

const isChecked = computed((): Function => {
  return (factorKey: String) => topImportantFactors.value.includes(factorKey)
})

const isDisabled = computed((): Function => {
  return (factorKey: String) => topImportantFactors.value.length >= 3 && !topImportantFactors.value.includes(factorKey)
})

</script>

<template>
  <main>
    <template v-if="topImportantFactors.length > 0">
      <input
        v-for="factor, i in topImportantFactors"
        :key="`${i}_${factor}`"
        type="hidden"
        :name="factorsName"
        :value="factor"
      >
    </template>
    <input
      v-else
      type="hidden"
      :name="factorsName"
    >

    <div
      id="factors-checkbox"
      class="grid grid-cols-2 gap-4 mb-8"
    >
      <div
        v-for="value in Factors"
        :key="value"
      >
        <label
          :for="value"
          class="flex items-center py-3 pl-3 font-medium rounded-lg border border-gray-300 hover:cursor-pointer drop-shadow-sm"
          :class="isChecked(value) ? 'border-primary bg-primary-200' : 'border-gray-300'"
        >
          <input
            :id="value"
            v-model="topImportantFactors"
            type="checkbox"
            :value="value"
            :disabled="isDisabled(value)"
            class="mr-2 w-4 h-4 rounded border-gray-300 hover:cursor-pointer focus:ring-0 focus:ring-offset-0 disabled:bg-gray-100 peer text-primary"
          >
          <span class="text-sm text-gray-700 peer-disabled:text-gray-400">{{ FactorsLabel[value] }}</span>
        </label>
      </div>
    </div>

    <div v-if="topImportantFactors.includes('other')" class="mb-8">
      <div class="flex justify-between">
        <label class="text-sm font-medium text-gray-700">Other factor</label>
        <span class="text-xs text-gray-400">Required *</span>
      </div>
      <input
        v-model="otherFactor"
        :name="otherFactorName"
        type="text"
        class="w-full rounded-lg border border-gray-300 drop-shadow-sm"
        placeholder="Consideration for..."
      >
    </div>

    <div v-show="topImportantFactors.length > 0">
      <div
        id="factors-draggable"
        class="mb-8"
      >
        <label class="text-sm font-medium text-gray-700">Rank your choices in order of importance</label>
        <ul class="list-group">
          <draggable
            v-model="topImportantFactors"
            :class="{ animation: 200 }"
            ghost-class="opacity-0"
            item-key="key"
            @start="drag = true"
            @end="drag = false"
          >
            <template #item="{ element }">
              <li class="py-3 pl-3 mb-3 bg-white rounded-lg border border-gray-300 hover:cursor-pointer list-group-item drop-shadow-sm">
                <i class="pr-2 fa-solid fa-grip-dots-vertical" /><span class="font-medium text-gray-700">{{ FactorsLabel[`${element}`] }}</span>
              </li>
            </template>
          </draggable>
        </ul>
      </div>

      <div
        id="other-factors"
        class="mb-8 w-full drop-shadow-sm"
      >
        <label class="text-sm font-medium text-gray-700">Have any context for those top three choices that would be helpful?</label>
        <textarea
          v-model="factorsText"
          :name="factorsContextName"
          placeholder="Ex: PMP certification is a must-have, AWS experience is a must-have, would like an equal ratio of female and male candidates, etc."
          class="w-full placeholder-gray-400 text-black rounded-lg border border-gray-300 drop-shadow-sm h-[125px]"
        />
      </div>
    </div>
  </main>
</template>

<style>
.flip-list-move {
  transition: transform 0.3s;
}
</style>

import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  static targets = ['subjectDestination', 'bodyDestination', 'templateAsTrixHtml', 'templateSubject']

  declare subjectDestinationTarget: HTMLInputElement
  declare bodyDestinationTarget: HTMLElement
  declare templateAsTrixHtmlTarget: HTMLElement
  declare templateSubjectTarget: HTMLElement

  copyTemplate (): void {
    const bodySource = document.querySelector('trix-editor[input*=body_trix_input]') as HTMLInputElement
    const submitButton = document.querySelector('input[type=submit]') as HTMLInputElement
    bodySource.value = this.templateAsTrixHtmlTarget.innerText + '<br>' + bodySource.value

    const subjectSource = document.querySelector('trix-editor[input*=stage_subject]') as HTMLInputElement
    subjectSource.value = document.createElement('div').innerHTML = this.templateSubjectTarget.innerText + ' ' + subjectSource.innerText

    submitButton.disabled = false
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  static values = { href: String }

  declare readonly hrefValue: string

  connect (): void {
    this.element.addEventListener('click', () => {
      Turbo.visit(this.hrefValue)
    })
  }
}

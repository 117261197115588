import { Controller } from '@hotwired/stimulus'
import ModalController from './modal-controller'
import FormController from './form-controller'

type NavigationEvent = CustomEvent<{ url: string }>

export default class extends Controller {
  static outlets = ['form', 'modal']

  private currentNavigation: NavigationEvent | undefined

  declare formOutlets: FormController[]
  declare modalOutlet: ModalController

  onUnload (event: BeforeUnloadEvent): void {
    if (this.hasUnsavedForm) {
      event.preventDefault()
      event.returnValue = ''
    }
  }

  onNavigation (event: NavigationEvent): void {
    if (this.hasUnsavedForm) {
      event.preventDefault()
      this.currentNavigation = event
      this.modalOutlet.show()
    }
  }

  navigate (): void {
    this.unsavedForm.cleanse()

    void this.modalOutlet.hide().then(() => {
      Turbo.visit(this.currentNavigation?.detail.url)
    })
  }

  submit (): void {
    this.unsavedForm.submit()
    this.navigate()
  }

  private get unsavedForm (): any {
    return this.formOutlets.find((outlet) => outlet.dirty)
  }

  private get hasUnsavedForm (): boolean {
    return this.unsavedForm !== undefined
  }
}

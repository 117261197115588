import { Controller } from '@hotwired/stimulus'

type Tab = HTMLLinkElement | HTMLButtonElement
type TurboBeforeAttributeMorphEvent = CustomEvent<{ attributeName: string }>

export default class extends Controller<HTMLElement> {
  static targets = ['tab', 'content']

  declare readonly tabTargets: Tab[]
  declare readonly contentTargets: HTMLElement[]

  connect (): void {
    this.showTab()
    addEventListener('turbo:before-morph-attribute', (e) => { this.preserveTabIndex(e) })
    addEventListener('turbo:morph', () => { this.showTab() })
  }

  preserveTabIndex (e: TurboBeforeAttributeMorphEvent): void {
    if (e.target === this.element && e.detail.attributeName === 'data-tabs-index') {
      e.preventDefault()
    }
  }

  change (e: PointerEvent): void {
    this.index = this.tabTargets.indexOf(e.currentTarget as Tab)
  }

  showTab (): void {
    this.tabTargets.forEach((tab, index) => {
      tab.classList.toggle('active', index === this.index)
      this.contentTargets[index].classList.toggle('hidden', index !== this.index)
    })
  }

  get index (): number {
    if (this.data.has('index')) {
      return parseInt(this.data.get('index') as string)
    } else {
      return 0
    }
  }

  set index (value: number) {
    this.data.set('index', value.toString())
    this.showTab()
  }
}

import { Source, Response } from '.'
import { Result } from '../results'
import Fuse from 'fuse.js'

export const staticSource = (data: string[]): Source => ({
  fetch: async (query: string, field: string): Promise<Response> => {
    if (query === '') {
      return { data: data.map(datum => textToResult(datum)) }
    }

    const fuseOptions = { threshold: 0.2 }
    const fuse = new Fuse(data, fuseOptions)
    const fuseResults = fuse.search(query)
    const results = fuseResults.map(result => textToResult(result.item))
    return { data: results }
  }
})

function textToResult (text: string): Result {
  return {
    id: text,
    value: text,
    text1: text
  }
}

// @ts-expect-error: stimulus does not offer types
import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  static targets = ['element']

  declare elementTarget: HTMLElement

  show (): void {
    this.elementTarget.classList.toggle('hidden', false)
  }

  hide (): void {
    this.elementTarget.classList.toggle('hidden', true)
  }

  toggle (): void {
    this.elementTarget.classList.toggle('hidden')
  }
}

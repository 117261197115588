import { Controller } from '@hotwired/stimulus'

interface TurboFrameBeforeRenderEvent {
  detail: {
    render: (currElement: Element, newElement: Element) => void
  }
}

export default class extends Controller {
  preserve (event: TurboFrameBeforeRenderEvent): void {
    const scrollTop = this.element.scrollTop
    const originalRender = event.detail.render

    event.detail.render = (currElement, newElement) => {
      originalRender(currElement, newElement)
      this.restoreScroll(this.element, scrollTop)
    }
  }

  private restoreScroll (element: Element, scrollTop: number): void {
    const elementAfterRendering = document.querySelector(`#${element.id}`)

    if (elementAfterRendering !== null) {
      elementAfterRendering.scrollTop = scrollTop
    }
  }
}

<script setup lang="ts">
import { Result } from './results'
import { uniqueId } from 'lodash-es'
import { useComboBoxStore, ComboBoxStore } from './store'

const props = defineProps<{
  name: string
  candidates: string[]
}>()

const store: ComboBoxStore = useComboBoxStore(uniqueId('candidates'))
const { query, selections } = storeToRefs(store)
const { useInputValueByDefault } = toRefs(store)

selections.value = props.candidates.map(candidate => {
  return store.textToResult(candidate)
})

function addCandidate (): void {
  if (query.value.replace(/\s/g, '').length > 0) {
    const result: Result = store.textToResult(query.value.trim())
    store.selectResult(result)
  }
}

function onPaste (event: ClipboardEvent): void {
  query.value = event.clipboardData?.getData('text/plain')
  addCandidate()
}
</script>

<template>
  <ComboBoxInputs
    :name="name"
    :selections="selections"
    :use-input-value-by-default="useInputValueByDefault"
  />

  <div class="relative">
    <input
      ref="queryInput"
      v-model="query"
      type="text"
      class="w-full placeholder-gray-300 text-black rounded-lg border border-gray-300 drop-shadow-sm"
      placeholder="http://www.linkedin.com/in/your-candidate"
      @keydown.enter.prevent="addCandidate"
      @paste="onPaste"
    >

    <button
      class="absolute right-3 top-1/2 text-gray-700 transform -translate-y-1/2 add-candidate"
      @click.prevent="addCandidate"
    >
      <i class="fa fa-plus" />
    </button>
  </div>

  <div class="pt-2">
    <ResultPill
      v-for="result in selections"
      :key="result.id"
      :store="store"
      :result="result"
    />
  </div>
</template>

<script setup lang="ts">
import ComboBoxV2 from './ComboBoxV2.vue'
import createGraphQLSource from './sources/createGraphQLSource'
import { uniqueId } from 'lodash-es'
import { useComboBoxStore, ComboBoxStore } from './store'
import { onBeforeMount } from 'vue'

const props = defineProps<{
  name: string
  title: string
}>()

const store: ComboBoxStore = useComboBoxStore(uniqueId('titleStore'))
const { source, query, isSingleSelect, useInputValueByDefault, field } = storeToRefs(store)

onBeforeMount(async () => {
  source.value = await createGraphQLSource('PdlAutocompleteQuery')
  isSingleSelect.value = true
  useInputValueByDefault.value = true
  query.value = props.title
  field.value = 'title'
})

</script>

<template>
  <ComboBoxV2
    :name="name"
    :store="store"
    :readonly="true"
    placeholder="Search Title"
  />
</template>

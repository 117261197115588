import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash-es'

export default class extends Controller<HTMLElement> {
  static readonly targets = ['popover', 'form']
  search: (event: InputEvent) => void = () => {}

  declare popoverTarget: HTMLElement
  declare formTarget: HTMLFormElement
  declare hasFormTarget: boolean

  initialize (): void {
    this.search = debounce(this._search, 300)
  }

  show (): void {
    this.popoverTarget.showPopover()
  }

  hide (): void {
    this.popoverTarget.hidePopover()
  }

  private _search (event: InputEvent): void {
    const inputValue = (event?.target as HTMLInputElement).value

    if (inputValue?.length > 0) {
      this.show()
    } else {
      this.hide()
    }

    if (this.hasFormTarget) {
      this.formTarget.requestSubmit()
    }
  }
}

import { Controller } from '@hotwired/stimulus'
import Trix from 'trix'

interface ITrix extends HTMLElement {
  editor: Trix.Editor
  toolbarElement: Trix.ToolbarElement
}

export default class extends Controller<HTMLElement> {
  static targets = ['customGroup']
  declare customGroupTargets: HTMLTemplateElement[]

  connect (): void {
    this.trix.addEventListener('trix-file-accept', (event: Trix.TrixFileAcceptEvent) => {
      event.preventDefault()
    })

    this.customGroupTargets.forEach(customGroup => this.addCustomGroup(customGroup))

    const { toolbarElement } = this.trix
    const input = toolbarElement.querySelector('input[name=href]')
    const linkButton = toolbarElement.querySelector('[data-trix-method="setAttribute"]')

    input.type = 'text'
    input.pattern = '[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}([-a-zA-Z0-9()@:%_+.~#?&amp;//=]*)'
    input.placeholder = 'Ex: http://www.hootrecruit.com'

    function validateURL (): void {
      if (!RegExp(input.pattern).test(input.value)) {
        input.setCustomValidity(' ')
        input.style.borderColor = '#FFDDDD'
      } else {
        input.setCustomValidity('')
        input.style.borderColor = ''
      }
    }

    linkButton.addEventListener('click', () => {
      validateURL()
    })

    input.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        e.preventDefault()
        validateURL()
      }
    })

    // Open the link in browser new tab for trix https://github.com/basecamp/trix/issues/55
    addEventListener('click', function (event) {
      const el = event.target as HTMLElement

      if (el.closest('.trix-content') != null) {
        if (el.tagName === 'A' && !el.isContentEditable) {
          const href = el.getAttribute('href')

          if (href !== null && !href.startsWith('http')) {
            el.setAttribute('href', `https://${href}`)
          }
          el.setAttribute('target', '_blank')
        }
      }
    }, true)
  }

  insertVariable ({ params: { variable } }: { params: { variable: string }}): void {
    this.editor.insertString(`{{ ${variable} }}`)
  }

  insertContent (event: MouseEvent): void {
    const element = event.currentTarget as HTMLLinkElement | HTMLButtonElement
    this.editor.insertHTML(element.innerHTML)
  }

  clear (): void {
    this.editor.recordUndoEntry('Clearing text')
    this.editor.composition.replaceHTML('')
  }

  private addCustomGroup (customGroup: HTMLTemplateElement): void {
    const anchor: HTMLElement = this.getButtonGroup('history-tools')
    const newGroup: HTMLElement = customGroup.content.cloneNode(true) as HTMLElement
    this.controls.insertBefore(newGroup, anchor)
  }

  private getButtonGroup (name: string): HTMLElement {
    return this.element.querySelector(`[data-trix-button-group="${name}"]`) as HTMLElement
  }

  private get trix (): ITrix {
    return this.element.querySelector('trix-editor') as ITrix
  }

  private get editor (): Trix.Editor {
    return this.trix.editor
  }

  private get controls (): HTMLElement {
    return this.element.querySelector('.trix-button-row') as HTMLElement
  }
}

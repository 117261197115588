import { Controller } from '@hotwired/stimulus'
import captureEvent from '@/v2/posthog'

export default class extends Controller<HTMLElement> {
  connect (): void {
    this.element.addEventListener('blur', (e: FocusEvent) => {
      const input: HTMLInputElement = e.target as HTMLInputElement

      if (input.name !== '') {
        captureEvent('Intake Form', input.name)
        input.form?.requestSubmit()
      }
    }, true)
  }
}

<script setup lang="ts">
import type { ComboBoxStore } from './store'
import { toRefs } from 'vue'

const props = defineProps<{
  store: ComboBoxStore
  result: any
}>()

const { selectResult } = toRefs(props.store)
</script>

<template>
  <div
    class="py-1 pl-3 cursor-pointer hover:bg-primary-50"
    @click.stop="selectResult(result)"
  >
    <span
      :id="result.id"
      class="inline-block text-sm font-medium text-gray-700"
    >{{ result.text1 }}</span>
  </div>
</template>

import { Controller } from '@hotwired/stimulus'
import posthog from 'posthog-js'
import captureEvent from '@/v2/posthog'

export default class extends Controller<HTMLElement> {
  static values = { userId: String, apiKey: String, v1Tenant: Boolean, impersonatorEmail: String }

  declare readonly apiKeyValue: string
  declare userIdValue: string
  declare readonly v1TenantValue: boolean
  declare readonly impersonatorEmailValue: string

  connect (): void {
    if (this.apiKeyValue.length > 0 && !this.v1TenantValue) {
      this.initializePosthog()
    }

    if (window.location.pathname === '/start') {
      captureEvent('New requisition form started', null)
    }
  }

  initializePosthog (): void {
    posthog.init(this.apiKeyValue, {
      api_host: 'https://app.posthog.com',
      loaded: (ph) => {
        if (this.userIdValue !== '') {
          console.log('Identifying', this.userIdValue, 'with Posthog')
          ph.identify(this.userIdValue)
        }
      }
    })

    if (this.impersonatorEmailValue.length > 0) {
      posthog.register({ impersonator_email: this.impersonatorEmailValue })
    }
  }

  reset (): void {
    if (posthog !== undefined) {
      posthog.reset()
    }
  }
}
